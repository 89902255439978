import React, { useState, useEffect, useContext } from "react";
import {
    GetFundSubscriptionInfo,
    GetInvestorFundInvestment,
} from "../../../Adapters/FundActions";
import { useParams, useNavigate } from "react-router-dom";
import { getInvestorFundInvestment } from "../../../APIs/FundActions";
import { getFirmUser, clientDetails, clientData, getInvestments } from "../../../APIs/WealthManagementAPI";
import { createBulkInvestments, getFundInvestmentInformation, getUninvestedClients } from "../../../APIs/ConfirmInvestmentAPI";
import LoadingAnimation from "./../../../Components/LoadingAnimation/LoadingAnimation.js";
import EnhancedKYC from "../../../Components/EnhancedKYC/EnhancedKYC";
import SubDocs from "../../../Layouts/SubDocs/SubDocsSyon";
import SelectableTable from "./SelectableTable";
import FilterComponent from "./FilterComponent";

import BankAccountForm from "./BankAccountForm";

interface ConfirmInvestmentsProps {
    user: any;
    client_id: any;
}

const ConfirmInvestments: React.FC<ConfirmInvestmentsProps> = ({ user, client_id}) => {
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState("Investment");
    const [investmentAmount, setInvestmentAmount] = useState<string>("");
    const [investmentId, setInvestmentId] = useState<string>("");

    const [client, setClient] = useState<any>({});

    const [fundId, setFundId] = useState<string>("");
    const [submitError, setSubmitError] = useState<string>("");
    const [clients, setClients] = useState<any[]>([]);

    const [sigComplete, setSigComplete] = useState<boolean>(false);
    const [prefillComplete, setPrefillComplete] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingClient, setLoadingClient] = useState<boolean>(true);


    const { id } = useParams();

    const [fundInfo, setFundInfo] = useState<any>({
        name: "Placeholder",
        minimumInvestment: 5000, // Minimum investment amount in USD
        id: ''
    });

    useEffect(
        () => { setSubmitError("") }, [investmentAmount]
    );

    useEffect(() => {

        async function getFundInvestmentInformationObject() {
            try {
                const response = await getFundInvestmentInformation(id);
                const fundInvestmentInformationData = response.data;
                setFundInfo((prev: any) => ({
                    ...prev, id: fundInvestmentInformationData['id'],
                    remaining: fundInvestmentInformationData.max_fund_size - fundInvestmentInformationData.committed_allocation_amount,
                    minimumInvestment: fundInvestmentInformationData['minimum_allocation_amount'],
                    name: fundInvestmentInformationData['name']
                }))
            } catch (err) {
                if (process.env.REACT_APP_DEBUG === "1") {
                    console.log("There is an error.");
                    console.error(err);
                }
            }
            
        }

        getFundInvestmentInformationObject();
        


    }, []);

    useEffect(() => {

        async function populateClient() {
            try {
                const wmFirmUserInfo = await getFirmUser();
                if (wmFirmUserInfo.data.clients.length === 1) {
                    setClient(wmFirmUserInfo.data.clients[0]);
                }
                else if (wmFirmUserInfo.data.clients.length === 0) navigate("/account/add"); // Redirect


            } catch (err) {
                if (process.env.REACT_APP_DEBUG === "1") {
                    console.log("There is an error.");
                    console.error(err);
                }
            }
            
        }

        async function pullClients() {
            try {
                const wmFirmUserInfo = await getFirmUser();
                setClients(wmFirmUserInfo.data.clients);


            } catch (err) {
                if (process.env.REACT_APP_DEBUG === "1") {
                    console.log("There is an error.");
                    console.error(err);
                }
            }
            
        }

        setLoadingClient(true);
        if (user && client_id !== undefined) {
            async function loadClient() {
            const wmFirmUserInfo = await getFirmUser();
            setClient(wmFirmUserInfo.data.clients.find( (client: any) => client.client_id ===  client_id));}
            loadClient();
        }

        else {
        user && user.userRole === "Self-Serve" && populateClient();
        user && user.userRole !== "Self-Serve" && pullClients(); }
        setLoadingClient(false);

    }, [user]);

    useEffect(() => {
        async function getUninvestedClientsData() {
            setLoading(true);
            const wmFirmUserInfo = await getFirmUser();
            console.log(`client ${client_id}`);

            console.log(client)

            if (user.userRole !== 'Self-Serve' && Object.keys(client).length === 0) {
                setSelectedTab("Select Client") // Always load select client screen initially for advisors
            }

            else if (Object.keys(client).length > 0) {
                setFundId(fundInfo.id);
                const investment = await getInvestorFundInvestment(id, client.account_id);
                const isInvested = investment.data.length !== 0;

                if (isInvested) {
                    setInvestmentId(investment.data.id);

                    const status = investment.data.status

                    if (status === "P") {
                        if (investment.data.bank_account === null) {
                            setSelectedTab("Bank Account Info");
                        }
                        else {
                            setSelectedTab("Document Collection");
                        }
                    }

                    else if (status === "PD") {
                        if (investment.data.bank_account === null) {
                            setSelectedTab("Bank Account Info");
                        }
                        else if (investment.data.subdocs_signed === false) {
                            setSelectedTab("Fill Subdocs");
                        }
                        else if (investment.data.subdocs_signed === true) {
                            setSelectedTab("Signature");
                        }
                    }
                    else if (status === "PF")
                        setSelectedTab("Wire Instructions");
                }
                else setSelectedTab("Investment");
            }
            setLoading(false);


        }

        fundInfo && fundInfo.id && user && getUninvestedClientsData();

    }, [fundInfo, client]);

    useEffect(() => {
        if (prefillComplete === true) {
            setSelectedTab("Signature");
        }
    }, [prefillComplete]);

    useEffect(() => {
        if (sigComplete === true) {
            setSelectedTab("Wire Instructions");
        }
    }, [sigComplete]);

    const [selClient, setSelClient] = useState("");

    const firmClients: any[] = [];




    const tabs = [
        ...[(user && user.userRole !== "Self-Serve") ? ({ label: "Select Client" }) : {}],
        { label: "Investment" },
        { label: "Bank Account Info" },
        { label: "Document Collection" },
        { label: "Fill Subdocs" },
        { label: "Signature" },
        { label: "Wire Instructions" },
        { label: "Funded" },
    ];

    const submitInvestments = async () => {
        setLoading(true);

        const amount = parseInt(investmentAmount.replace(/[^0-9]/g, ''));

        if (isNaN(amount)) {
            setSubmitError("Amount is required.")
        }
        else if (amount < fundInfo.minimumInvestment) {
            setSubmitError("Amount is less than minimum.");
        }
        else if (amount > fundInfo.remaining) {
            setSubmitError("Amount is greater than remaining allocation.")
        }

        else {
            const payloadObject = {
                fund_id: fundInfo.id,
                investments: [{
                    "id": client.client_id,
                    "proposed_investment_amount": amount
                }],
            };

            try {
                let requestResponse = await createBulkInvestments(payloadObject);
                const resp = await clientData(client.client_id);

                const investment = await getInvestorFundInvestment(id, client.account_id);
                setInvestmentId(investment.data.id);
                // Return updated KYC Requirements?
                setSelectedTab("Bank Account Info");
            } catch (err) {
                setSubmitError('Error.');
            }
        };
        setLoading(false);
    };

    // Utility function to format numbers as USD currency inside the input
    const formatCurrency = (amount: string | number) => {
        if (!amount) return '';
        const value = typeof amount === 'string' ? parseFloat(amount.replace(/,/g, '')) : amount;
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(value);
    };

    const renderTabContent = () => {

        // Handle input change with currency formatting
        const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value.replace(/[^\d.]/g, ''); // Strip out any non-numeric characters except periods
            if (!rawValue) {
                setInvestmentAmount(""); // If input is cleared
                return;
            }
            const numericValue = parseFloat(rawValue);
            if (!isNaN(numericValue)) {
                setInvestmentAmount(formatCurrency(numericValue)); // Set the formatted value
            }
        };

        if (loading||loadingClient) {
            return (<LoadingAnimation loading={true} />);
        }

        else if (selectedTab === "Select Client" && user) {
            return (
                <div className="tw-p-4 tw-space-y-6">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Select Client</h1>
                    Select a client and proceed to the next step.
                         <div>
                        { clients  && <SelectableTable data={clients.filter((client: any) => client.ss === false)} fund={id} setSelClient={setSelClient}/> }
                            <button
                                type="submit"
                                disabled={["", undefined, null].includes(selClient)}
                                onClick={() => {
                                    setClient(selClient);
                                }}
                                className="tw-mt-4 tw-bg-indigo-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-md hover:tw-bg-indigo-700"
                            >
                                Next step
                            </button>
                        </div>
                    </div>

            )
        }
        else if (selectedTab === "Investment" && user) {
            return (
                <div className="tw-p-4 tw-space-y-6" style={{ "width": "68%" }}> {/* Vertical spacing */}
                    {/* Confirm Investment Header */}
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Confirm Investment</h1>

                    {/* Fund Info Box */}
                    <div className="tw-bg-gray-100 tw-px-4 tw-py-2 tw-rounded-md tw-shadow-md" style={{ "width": "50%" }}>
                        <h3 className="tw-text-lg tw-font-semibold tw-mb-1">{fundInfo.name}</h3>
                        <p className="tw-text-sm tw-text-gray-700">
                            Minimum Investment: {formatCurrency(fundInfo.minimumInvestment)}<br />
                        </p>


                    </div>

                    {/* Investment Amount Input */}
                    <div>
                        <h2 className="tw-text-xl tw-font-semibold tw-mb-4">Investment Amount</h2>
                        <div className="tw-space-y-4">
                            <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                Enter the amount you wish to invest:
                            </label>

                            {/* Input with formatted currency */}
                            <div className="tw-relative tw-mt-1">
                                <input
                                    type="text"
                                    id="amount"
                                    value={investmentAmount}
                                    onChange={handleAmountChange}
                                    className="tw-block tw-w-full tw-pl-3 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-sm:tw-text-sm"
                                    placeholder="$0.00"
                                />
                            </div>
                            {submitError && <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                {submitError}
                            </label>}
                            <button
                                type="submit"
                                onClick={submitInvestments}
                                className="tw-mt-4 tw-bg-indigo-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-md hover:tw-bg-indigo-700"
                            >
                                Next step
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        else if (selectedTab === "Bank Account Info" && investmentId !== "") {
            return (
                <div className="tw-p-4 tw-space-y-6">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Bank Account Info</h1>
                    <div className="tw-space-y-4">
                        <BankAccountForm investment={investmentId} clientId={client.client_id} setSelectedTab={setSelectedTab} />
                    </div></div>);
        }

        else if (selectedTab === "Document Collection") {
            return (
                <div className="tw-p-4 tw-space-y-6">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">KYC Document Collection</h1>
                    <div className="tw-space-y-4">
                        <EnhancedKYC clientId={client.client_id}/>
                        <button
                            className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                            onClick={() => setSelectedTab("Fill Subdocs")}
                        >
                            Skip and submit later
                        </button>
                    </div></div>);
        }


        else if (selectedTab === "Fill Subdocs" && client && client.account_id && fundId !== "") {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Subdocs</h1>
                <div className="tw-space-y-4">
                    <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                        {<SubDocs isPrefill={true} fundId={fundId} accountId={client.account_id} setSigComplete={setPrefillComplete} />}
                    </label></div>
            </div>);
        }
        else if (selectedTab === "Signature" && client && client.account_id && fundId !== "" && user) {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Signature</h1>
                <div className="tw-space-y-4">

                    {user.userRole === "Self-Serve" ? (<SubDocs isPrefill={false} fundId={fundId} accountId={client.account_id} setSigComplete={setSigComplete} />)
                        : (<>Waiting for client to complete signature. </>)}
                </div>
            </div>);
        }
        else if (selectedTab === "Wire Instructions") {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Confirmation</h1>
                <div className="tw-space-y-4">
                    <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                        Wire instructions will be shared through email.
                    </label></div>
            </div>);
        }
        else if (selectedTab === "Funded") {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Funded</h1>
                <div className="tw-space-y-4">
                    <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                        Initial capital call is funded.
                    </label></div>
            </div>);
        }
    };


    return (
        <div className="tw-flex container-box">
            <div className="tw-w-1/4 tw-bg-gray-100 tw-p-4">
                <div className="tw-space-y-4">
                    {tabs.map((tab) => (
                        <div
                            key={tab.label}
                            className={`tw-flex tw-items-center tw-px-3 tw-py-2 tw-rounded-md ${selectedTab === tab.label ? "tw-bg-indigo-600 tw-text-white" : "tw-bg-gray-200 tw-text-gray-600"
                                }`}
                        >
                            <span className="tw-text-lg tw-font-medium">{tab.label}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Content Section */}
            <div className="tw-w-3/4 tw-p-4">{renderTabContent()}</div>
        </div>
    );
};

export default ConfirmInvestments;