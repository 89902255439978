import React, { useState } from 'react';

type StatusOption = "Uninvested" | "Funded";

interface FilterComponentProps {
  onSearch: (searchTerm: string) => void;
  onStatusChange: (status: StatusOption) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ onSearch, onStatusChange }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<StatusOption | "">("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as StatusOption;
    setSelectedStatus(value);
    onStatusChange(value);
  };

  return (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search name or email..."
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
      />

      {/* Status Dropdown */}
      <select
        value={selectedStatus}
        onChange={handleStatusChange}
        style={{ padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
      >
        <option value="">Filter by Status</option>
        <option value="Uninvested">Uninvested</option>
        <option value="Pending Bank Info">Pending Bank Info</option>
        <option value="Funded">Funded</option>
        <option value="Pending Funding">Pending Funding</option>
        <option value="Pending Client Sig">Pending Client Sig</option>
        <option value="Pending Prefill">Pending Prefill</option>

      </select>
    </div>
  );
};

export default FilterComponent;
