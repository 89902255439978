import React, { useState, useEffect } from "react";
//import "./Styles/Styles.scss";
import HelloSign from "hellosign-embedded";
import {
  getSubdocLink,
  resendMagicLink,
  clickToSign,
} from "../../APIs/WealthManagementAPI";
import SnackBar from "./../../Components/Alerts/SnackBar/SnackBar";
import MuiButton from "./../../Components/Buttons/MuiButton/MuiButton";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../Components/LoadingAnimation/LoadingAnimation";
import Spinner1 from "../../Components/Spinners/Spinner1";
import TextInputField from "../../Components/InputFields/TextInputField/TextInputField";

const clickSign = async (code, setDocsSigned, fullName) => {
  const resp = await clickToSign(code, fullName);
  setDocsSigned(true);
};

function SydecarSigModal({
  showModal,
  embeddedLink,
  setShowModal,
  code,
  setDocsSigned,
  signatory,
}) {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);

  return (
    <Modal
      show={showModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontFamily: "Montserrat" }}
        >
          Subscription Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center", paddingLeft: "10%" }}>
          <iframe height={"600px"} width={"600px"} src={`${embeddedLink}`} />
        </div>
        <div className="col">
          <center>
            By clicking the sign button, your document signature process will be
            considered complete. Please review the document prior to clicking
            the sign button.
          </center>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-50 pe-0">
          <TextInputField
            value={fullName}
            setValue={setFullName}
            setValueError={setFullNameError}
            valueError={fullNameError}
            label={"Enter your full name"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={false}
            id={"client-first-name-input"}
          />
        </div>
        <button
          type="buton"
          class="btn btn-lg btn-class justify-self-start me-md-5"
          onClick={() => {
            if (fullName === "") {
              setFullNameError("Name must be provided.");
              return;
            }
            clickSign(code, setDocsSigned, fullName);
            setShowModal(false);
          }}
          size={"large"}
        >
          Please click here to sign after reviewing the document
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function SubDocs() {
  const { code } = useParams();
  const [docsSigned, setDocsSigned] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [linkResent, setLinkResent] = useState(false);
  const [embeddedLink, setEmbeddedLink] = useState("");
  const [signatory, setSignatory] = useState("");

  const [sydecar, setSydecar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [docsAlreadySigned, setDocsAlreadySigned] = useState(false);

  const clientId =
    process.env.REACT_APP_API_ENDPOINT ===
      "https://api.staging.investwithaqua.com/"
      ? "9c4a35463ca1aaffef6e10f29104b2b4"
      : process.env.REACT_APP_API_ENDPOINT === "https://api.investwithaqua.com/"
        ? "78ef255f844acfc6eb47c48e71e28bb2"
        : process.env.REACT_APP_API_ENDPOINT ===
          "https://api.wm-demo.investwithaqua.com/" ||
          process.env.REACT_APP_API_ENDPOINT ===
          "https://api.wm-demo.investwithaqua.com"
          ? "1eb021ef3831c851cffe3ad1f80e94ee"
          : "ee92b78dc5a4c9124758eb38095d1e82";

  const client = new HelloSign({
    clientId: clientId,
    element: document.getElementById("dropbox-sign-client"),
    skipDomainVerification:
      process.env.REACT_APP_API_ENDPOINT !== "https://api.investwithaqua.com"
        ? true
        : false,
  });

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const callSubDocsEndpoint = async (tries = 0) => {
    try {
      setLoading(true);
      setError(false);
      const resp = await getSubdocLink(code);
      if (
        resp.data.doc !== null &&
        resp.data.doc !== undefined &&
        resp.data.doc === "Signed"
      ) {
        setDocsAlreadySigned(true);
      } else {
        setDocsAlreadySigned(false);
        if (resp.data.msg === "Link is invalid.") {
          setError(true);
        }
        else if (resp.data.msg === null && (window.location.host.startsWith("syoncap")||window.location.host.startsWith("sigma"))) {
          if (tries < 10) {
            setPageLoading(true);
            await sleep(3000);
            await callSubDocsEndpoint(tries + 1);
          }
          else {
            setError(true);
          }
          setPageLoading(false);
          return;
        }
        else {
          if (!resp.data.msg.startsWith("https://aqua-api")) {
            setEmbeddedLink(resp.data.msg);
            client.open(resp.data.msg, {
              clientId: clientId,
              container: document.getElementById("dropbox-sign-client"),
            });

            client.on("finish", () => {
              window.close();
              setDocsSigned(true);
            });
          } else {
            /*async function getSignedURL() {
              var tempURL = await Storage.get(resp.data.msg, {
                level: "private",
              });
              setEmbeddedLink(tempURL);
           }
            getSignedURL();*/
            setEmbeddedLink(resp.data.msg + "#navpanes=0&zoom=115");
            setSignatory(resp.data.msg.signatory_name);
            setSydecar(true);
            setShowModal(true);
          }
        }
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const resend = async () => {
    setLinkResent(true);
    const resp = await resendMagicLink(code);
  };

  useEffect(() => {
    async function getSignedStatus() {
      setPageLoading(true);
      try {
        const resp = await getSubdocLink(code);
        if (
          resp.data.doc !== null &&
          resp.data.doc !== undefined &&
          resp.data.doc === "Signed"
        ) {
          setDocsAlreadySigned(true);
        }
        setPageLoading(false);
      } catch (err) {
        setError(true);
        setPageLoading(false);
      }
    }
    getSignedStatus();
  }, []);

  return (
    <div className="container-xxl container-fluid w-sm-75 w-100 mt-3 pt-5 justify-content-center">
      <div className="row g-0 w-100 mt-5 mb-2 text-center">
        {!pageLoading ? (
          !docsAlreadySigned ? (
            !docsSigned ? (
              <>
                <h2>Review and sign subscription documents</h2>
                <SnackBar
                  snackbarState={linkResent}
                  setSnackbarState={setLinkResent}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={"Magic link resent."}
                />
                <div>
                  {!loading && !error && (
                    <MuiButton
                      buttonText={"Review Subdocs"}
                      onClick={() => {
                        callSubDocsEndpoint();
                      }}
                      size={"medium"}
                    />
                  )}
                </div>
                {loading && <LoadingAnimation loading={true} />}
                {sydecar === false && !error && embeddedLink !== "" && (
                  <div id="dropbox-sign-client" />
                )}
                <SydecarSigModal
                  embeddedLink={embeddedLink}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setDocsSigned={setDocsSigned}
                  code={code}
                  signatory={signatory}
                />

                {error && (
                  <div className="w-full text-center">
                    <div className="flex flex-col items-center gap-6 mt-4 mx-auto max-w-md">
                      <p className="text-gray-700 text-lg font-medium block w-full">
                        Please try refreshing this page to access the
                        subscription documents. If the issue persists, this
                        signature link may be expired or invalid, so please use
                        the button below to resend a new link via email.
                      </p>
                      <div className="block w-full">
                        <MuiButton
                          buttonText="Click here to resend"
                          onClick={resend}
                          size="medium"
                          sxProps={{
                            marginTop: "16px",
                            marginBottom: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <h2>
                Thank you for signing the subscription documents, wire
                instructions will be sent.
              </h2>
            )
          ) : (
            <h2>
              These subscription documents have already successfully been
              signed.
            </h2>
          )
        ) : (
          <div className="d-flex justify-content-center w-100 align-items-center">
            <Spinner1 />
          </div>
        )}
      </div>
    </div>
  );
}
export default SubDocs;
