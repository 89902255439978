import React from "react";
import "./Styles.scss";

function RaptorTargetPerformanceSection({
  targetIRR,
  strategy,
  targetReturnMultiple,
  minimum,
  stage,
}) {
  return (
    <div class="col-12">
      <div class="col-12">
        <div class="row ms-3 me-3">
          <div class="two-by-two-grid rounded horizontal-border">
            <div class="row border-between vertical-border">
              <div class="col-6 two-by-two-grid-item">
                {" "}
                <div class="fund-target-header-text">
                  2024 Pro Forma Revenue
                </div>{" "}
                <span class="info-text">$754M</span>
              </div>
              <div class="col-6 two-by-two-grid-item">
                {" "}
                <div class="fund-target-header-text">
                  2024 Pro Forma Adjusted EBITDA
                </div>{" "}
                <span class="info-text">$202M</span>
              </div>
            </div>
            <div class="row border-between">
              <div class="col-6 two-by-two-grid-item">
                <div class="fund-target-header-text">Strategy</div>
                <span class="info-text">Portco.</span>
              </div>
              <div class="col-6 two-by-two-grid-item">
                <div class="fund-target-header-text">
                  Implied 2024 EBITDA Multiple
                </div>
                <span class="info-text">14.8X</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0 mb-3">
          <div class="row gx-3"></div>
        </div>
      </div>
    </div>
  );
}
export default RaptorTargetPerformanceSection;
